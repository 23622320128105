<template>
    <div class="course-container">
      <!-- <div class="player-container">
        <vue-core-video-player 
            src="https://video-1254204736.cos.ap-guangzhou.myqcloud.com/video/tutorials/%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%BE%AE%E4%BF%A1%E5%B0%8F%E7%A8%8B%E5%BA%8F%E6%95%99%E7%A8%8B%E5%8E%8B%E7%BC%A9%E7%89%882.0.mp4"
            controls="auto"
            preload="nona"
            title="小程序使用教程"
        ></vue-core-video-player>
      </div> -->
      <div class="videoWrap">
        <video-player  
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
           >
        </video-player>
    </div>
    </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';

export default {
    name: 'Course',
    data() {
        return {
            playerOptions: {
                // playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: true, // 浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                controls: true,
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '9:16', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                sources: [
                    {
                    src: 'https://video-1254204736.cos.ap-guangzhou.myqcloud.com/video/tutorials/%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%BE%AE%E4%BF%A1%E5%B0%8F%E7%A8%8B%E5%BA%8F%E6%95%99%E7%A8%8B%E5%8E%8B%E7%BC%A9%E7%89%882.0.mp4',  // 路径
                    type: 'video/mp4'  // 类型
                    }
                ],
                poster: 'https://video-1254204736.cos.ap-guangzhou.myqcloud.com/video/tutorials/%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E7%A0%81%E4%B8%8A%E7%BB%83%E4%B9%A0%E5%BE%AE%E4%BF%A1%E5%B0%8F%E7%A8%8B%E5%BA%8F%E8%A7%86%E9%A2%91%E6%95%99%E7%A8%8B%E5%B0%81%E9%9D%A2.png',
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖 Video.js 无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 时间分割线
                    durationDisplay: true, // 总时间
                    remainingTimeDisplay: true, // 剩余播放时间
                    progressControl: true, // 进度条 
                    fullscreenToggle: true  // 全屏按钮
                }
            }
        };
    },
    computed: {
        player() {
        return this.$refs.videoPlayer.player;
        },
    },
    components: {
        videoPlayer
    },
    created() {

    },
    mounted() {
        document.title = '码上练习微信小程序使用教程'
        // 通过 ref 访问 videoPlayer 组件实例
        this.$nextTick(() => {
            this.$refs.videoPlayer.player.load()
            this.$refs.videoPlayer.player.play()
        })
    },
    methods: {
        
    }
};
</script>

<style lang="scss" scoped>
.videoWrap{
  width: 100%;
  height: calc(100vh - 100px);
  ::v-deep video{
    object-fit: fill; // 消除播放器两边的黑色留白 （object-fit 知识详见下文拓展部分）
  }
  /* 视频开始的按钮样式 */
  ::v-deep.video-js .vjs-big-play-button{
    border-width: 3px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    line-height: 50px;
    background-color: rgba(0,0,0,0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  ::v-deep .vjs-poster {
    background-size: cover;
  }

  /* 滚动条的样式 */
  ::v-deep .video-js .vjs-control-bar{
    background-color: rgba(43, 51, 63, 0.7);
  }
  ::v-deep.video-js .vjs-play-progress{
    background-color: rgb(238,191,0);
  }
  ::v-deep.video-js .vjs-load-progress div{
    background-color: rgb(255, 255, 255);
  }
}
</style>
